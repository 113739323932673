import React from 'react';
import { WidgetTypes } from '../../../../features/dashboard/types/widget';
import TextWidget from '../../components/TextWidget';
import ChartWidget from '../../components/ChartWidget';
import TableWidget from '../../components/TableWidget';
import DateWidget from '../../components/DateWidget';
import TimeWidget from '../../components/TimeWidget';
import ListWidget from '../../components/ListWidget';
import { WidgetConfig } from '../../types/common';
import SelectWidget from '../../components/SelectWidget';
import DateTimeWidget from '../../components/DateTimeWidget';
import StatisticWidget from '../../components/StatisticWidget';
import styles from '../../Widget.module.less';

interface WidgetBodyProps {
  widgetType: WidgetTypes;
  data: any;
  config: WidgetConfig;
  onChange: (value: any) => void;
  parameter: any;
}

const WidgetBody: React.FC<WidgetBodyProps> = ({
  widgetType,
  data,
  config,
  onChange,
  parameter,
}) => {
  switch (widgetType) {
    case WidgetTypes.TEXT:
      return <TextWidget data={data} />;
    case WidgetTypes.CHART:
      return (
        <div className={styles.widgetContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              padding: config.padding || 10,
            }}
          >
            {config.title && <h2>{config.title}</h2>}
            <div style={{ flex: 1 }}>
              <ChartWidget data={data} config={config} />
            </div>
          </div>
        </div>
      );

    case WidgetTypes.TABLE:
      return (
        <div className={styles.widgetContainer} style={{ overflow: 'auto' }}>
          <TableWidget
            data={data}
            config={config}
            onChange={onChange}
            parameter={parameter}
          />
        </div>
      );
    case WidgetTypes.DATE:
      return (
        <div className={styles.widgetContainer}>
          <DateWidget
            parameter={parameter}
            config={config}
            onChange={onChange}
          />
        </div>
      );
    case WidgetTypes.TIME:
      return (
        <div className={styles.widgetContainer}>
          <TimeWidget
            parameter={parameter}
            config={config}
            onChange={onChange}
          />
        </div>
      );
    case WidgetTypes.DATETIME:
      return (
        <div className={styles.widgetContainer}>
          <DateTimeWidget
            config={config}
            onChange={onChange}
            parameter={parameter}
          />
        </div>
      );
    case WidgetTypes.LIST:
      return (
        <div className={styles.widgetContainer}>
          <ListWidget data={data} />
        </div>
      );
    case WidgetTypes.SELECT:
      return (
        <div className={styles.widgetContainer}>
          <SelectWidget
            data={data}
            config={config}
            onChange={onChange}
            parameter={parameter}
          />
        </div>
      );
    case WidgetTypes.STATISTIC:
      return (
        <div className={styles.statisticContainer}>
          <StatisticWidget data={data} config={config} />
        </div>
      );
    default:
      throw Error('Unknown widget type');
  }
};

export default WidgetBody;
