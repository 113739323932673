import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/es/button';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createEmptyDashboard } from '../../dashboardSlice';
import { generateUniqueName } from '../../../../utils';

interface AddDashboardButtonProps extends ButtonProps {
  hidden?: boolean;
}

const AddDashboardButton: React.FC<AddDashboardButtonProps> = props => {
  const { hidden = false } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  if (hidden) return null;

  const handleClick = () => {
    const dashboardName = generateUniqueName('Dashboard');
    dispatch(
      createEmptyDashboard(dashboardName, id => {
        history.push(`/dashboard/${id}`);
      }),
    );
  };

  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      onClick={handleClick}
      {...props}
    >
      <FormattedMessage
        id="dashboard.AddDashboard"
        defaultMessage="Добавить дашборд"
      />
    </Button>
  );
};

export default AddDashboardButton;
