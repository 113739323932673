import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import user from './features/user/userSlice';
import dashboard from './features/dashboard/dashboardSlice';
import settings from './features/settings/settingsSlice';
import auth from './features/auth/authSlice';
import i18n from './i18n/i18nSlice';
import error from './features/error/errorSlice';
import layout from './components/layout/layoutSlice';

export const store = configureStore({
  reducer: {
    auth,
    user,
    dashboard,
    settings,
    i18n,
    error,
    layout,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
