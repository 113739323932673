import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../authSlice';
import { getRandomNumber } from '../../../../utils';
import LoginPageLogo from '../../components/LoginPageLogo/LoginPageLogo';
import styles from './AccessDenied.module.less';
import { doLogout } from '../../../../hooks/useKeycloak';
import {
  altDescription,
  errorTitleFirstPart,
  errorTitleSecondPart,
  promoText,
  returnToAuthBtn,
} from './helper';
import Provider from '../../../../i18n/Provider';
import { getLocale } from '../../../../i18n/i18nSlice';

const AccessDenied = () => {
  const dispatch = useDispatch();
  const [randomImageNumber, setRandomImageNumber] = useState(1);
  const locale = useSelector(getLocale);

  useEffect(() => {
    setRandomImageNumber(getRandomNumber(5));
  }, []);

  const handleLogout = () => {
    doLogout();
    dispatch(logout());
  };

  return (
    <Provider locale={locale}>
      <Row className={styles.container} align="middle">
        <Col span={12} className={styles.imgWrapper}>
          <img src={`${randomImageNumber}.png`} alt={altDescription} />
        </Col>
        <Col span={12}>
          <div className={styles.content}>
            <LoginPageLogo />
            <div className={styles.infoContainer}>
              <div className={styles.promoText}>{promoText}</div>
              <div className={styles.errorTitleFirstPart}>
                {errorTitleFirstPart}
              </div>{' '}
              <div className={styles.errorTitleSecondPart}>
                {errorTitleSecondPart}
              </div>
              <Form.Item className={styles.loginContainer}>
                <Button type="primary" htmlType="submit" onClick={handleLogout}>
                  {returnToAuthBtn}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </Provider>
  );
};

export default AccessDenied;
