import React, { useEffect, useMemo, useState } from 'react';
import AddUserDrawer from './AddUser/AddUserDrawer';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, fetchUserList, selectUserList } from '../../settingsSlice';
import { getColumns, getPreparedData } from './columns';
import { useIntl } from 'react-intl';
import { User } from '../../types/user';
import styles from './styles.module.less';
import { isEven } from '../../../../utils';
import './styles.less';

const SettingsUsers: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isUserEditing, setIsUserEditing] = useState<boolean>(false);
  const [openedUser, setOpenedUser] = useState<User | null>(null);

  useEffect(() => {
    dispatch(fetchUserList({ size: 0, page: 0 }));
  }, [dispatch]);
  const userList = useSelector(selectUserList);

  const preparedData = useMemo(
    () => getPreparedData(userList, intl),
    [userList, intl],
  );

  const onAddClick = () => {
    setShowDrawer(!showDrawer);
    setIsUserEditing(false);
  };

  const handleDeleteClick = (userId: string) => {
    dispatch(
      deleteUser(userId, () => {
        dispatch(fetchUserList({ size: 0, page: 0 }));
      }),
    );
  };

  const getRowClassName = (record: User, index: number): string => {
    if (!isEven(index)) {
      return styles.oddTableRow;
    }

    return styles.evenRow;
  };

  const onRow = (user: User) => {
    return {
      onClick: () => {
        setShowDrawer(true);
        setIsUserEditing(true);
        setOpenedUser(user);
      },
    };
  };

  const columns = getColumns(preparedData);

  return (
    <>
      <div>
        {/* Временно закомментировали по просьбе Александра */}
        {/*{hasAddUserRights && (*/}
        {/*  <Button*/}
        {/*    type="text"*/}
        {/*    size="small"*/}
        {/*    className={styles.addButton}*/}
        {/*    onClick={onAddClick}*/}
        {/*    icon={<PlusCircleOutlined />}*/}
        {/*  >*/}
        {/*    <span>{addAccountLabel}</span>*/}
        {/*  </Button>*/}
        {/*)}*/}
        <AddUserDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          isUserEditing={isUserEditing}
          openedUser={openedUser}
          setOpenedUser={setOpenedUser}
        />
      </div>
      <div className={styles.antTable}>
        <Table<User>
          rowClassName={getRowClassName}
          dataSource={userList}
          columns={columns}
          onRow={onRow}
        />
      </div>
    </>
  );
};

export default SettingsUsers;
