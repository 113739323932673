import React, { useCallback, useState } from 'react';
import useKeyCloak from '../../../../hooks/useKeycloak';
import { login, selectGlobalConfig } from '../../authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../user/userSlice';
import { AxiosError } from 'axios';
import AccessDenied from '../../routes/AccessDenied/AccessDenied';

const KeycloakProvider = (props: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const globalConfig = useSelector(selectGlobalConfig);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  const redirect = (error: AxiosError) => {
    if (error.response?.status === 403) {
      setIsAccessDenied(true);
    }
  };

  const onAuthenticatedCallback = useCallback(() => {
    dispatch(fetchUser(() => setAuthenticated(true), redirect));
    dispatch(login());
  }, []);

  useKeyCloak({
    onAuthenticatedCallback,
    globalConfig,
  });

  if (isAccessDenied) return <AccessDenied />;

  if (!isAuthenticated) return null;

  return props.children;
};

export default KeycloakProvider;
