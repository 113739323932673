import React, { useCallback, useEffect, useState } from 'react';
import RGL, { WidthProvider, Layout } from 'react-grid-layout';
import { Dashboard, WidgetGrid } from '../../types/dashboard';
import cn from 'classnames';
import styles from './Dashboard.module.less';
import DashSkeleton from '../../../../components/DashSkeleton';
import {
  getLayout,
  isActiveWidgetGrid,
  updateDashboardGridPositions,
} from './helper';
import { ItemTypes } from '../../types/dndItemTypes';
import { useDrop } from 'react-dnd';
import WidgetPropsDrawer from '../../../../components/WidgetPropsDrawer';
import WidgetGridItem from '../../../../components/WidgetGridItem';
import { fetchSSe } from '../../../../utils/sseApi';
import {
  deleteWidgetError,
  receiveWidgetsData,
  setWidgetError,
} from '../../dashboardSlice';
import { useDispatch } from 'react-redux';

const GridLayout = WidthProvider(RGL);

interface DashboardFormProps {
  dashboard: Dashboard;
  isEditable: boolean;
  loading: boolean;
  onChange: (dashboard: Dashboard) => void;
  onSelectWidgetGrid: (widgetGrid: WidgetGrid) => void;
  activeWidgetGrid: WidgetGrid | null;
  onWidgetUpdate: (widgetGrid: WidgetGrid) => void;
  onDeleteWidgetGrid: (widgetId: string) => void;
  onChangeWidgetParameter: (widgetId: string, value: any) => void;
}

const DashboardForm: React.FC<DashboardFormProps> = ({
  dashboard,
  loading,
  isEditable,
  onChange,
  onSelectWidgetGrid,
  activeWidgetGrid,
  onWidgetUpdate,
  onDeleteWidgetGrid,
  onChangeWidgetParameter,
}) => {
  const dispatch = useDispatch();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: () => ({ name: 'Dashboard', id: dashboard.id }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [widgetPropsOpen, setWidgetPropsOpen] = useState<boolean>(false);
  const [ctrl, setCtrl] = useState(new AbortController());

  const abortSse = () => {
    ctrl.abort();
    setCtrl(new AbortController());
  };

  const toggleWidgetPropsOpen = useCallback(() => {
    setWidgetPropsOpen(!widgetPropsOpen);
  }, [widgetPropsOpen, setWidgetPropsOpen]);

  const handleDeleteWidgetGrid = useCallback(
    (widgetId: string) => {
      onDeleteWidgetGrid(widgetId);
      setWidgetPropsOpen(false);
    },
    [onDeleteWidgetGrid, setWidgetPropsOpen],
  );

  const updateDashboardLayout = (layout: Layout[]): void => {
    const newDashboard: Dashboard = updateDashboardGridPositions(
      dashboard,
      layout,
    );

    onChange(newDashboard);
  };

  const handleDragStop = (layout: Layout[]) => {
    updateDashboardLayout(layout);
  };

  const handleResizeStop = (layout: Layout[]) => {
    updateDashboardLayout(layout);
  };

  const isActive = isEditable && canDrop && isOver;

  useEffect(() => {
    if (!isEditable) {
      fetchSSe(`api/widget/stream/${dashboard.id}/all`, {
        ctrl,
        onmessage(ev) {
          const data = JSON.parse(ev.data);
          if (data?.error) {
            const errorMessage = data?.error || '';
            dispatch(setWidgetError({ id: data?.id, message: errorMessage }));
          } else {
            dispatch(deleteWidgetError(data.id));
            dispatch(
              receiveWidgetsData({
                widgetId: data?.id,
                data: data?.data,
              }),
            );
          }
        },
      });
    }
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      abortSse();
    }
  }, [isEditable]);

  useEffect(() => () => abortSse(), []);

  if (loading) {
    return <DashSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div ref={drop} className={styles.gridWrap}>
        <GridLayout
          className={cn('layout', {
            [styles.grid]: isEditable,
            [styles.active]: isActive,
          })}
          layout={getLayout(dashboard)}
          cols={12}
          rowHeight={40}
          isDraggable={isEditable}
          isResizable={isEditable}
          onDragStop={handleDragStop}
          onResizeStop={handleResizeStop}
        >
          {dashboard.widgetGrids.map(widgetGrid => {
            const isActiveWidget = isActiveWidgetGrid(
              widgetGrid,
              activeWidgetGrid,
            );

            const editableStyles = cn(styles.widgetGridItemWrap, {
              [styles.editableWidgetGridItem]: isEditable,
            });

            return (
              <div key={widgetGrid.id} className={editableStyles}>
                <WidgetGridItem
                  key={widgetGrid.id}
                  widgetGrid={widgetGrid}
                  editable={isEditable}
                  onSelect={onSelectWidgetGrid}
                  onUpdate={onWidgetUpdate}
                  active={isActiveWidget}
                  onEditBtnClick={toggleWidgetPropsOpen}
                  onChangeWidgetParameter={onChangeWidgetParameter}
                />
              </div>
            );
          })}
        </GridLayout>
      </div>
      {isEditable && activeWidgetGrid && (
        <WidgetPropsDrawer
          widgetGrid={activeWidgetGrid}
          onSubmit={onWidgetUpdate}
          onDeleteWidgetGrid={handleDeleteWidgetGrid}
          open={widgetPropsOpen}
          onClose={toggleWidgetPropsOpen}
        />
      )}
    </div>
  );
};

export default DashboardForm;
