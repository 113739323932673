import React, { useEffect, useState } from 'react';
import { Widget as IWidget } from '../../features/dashboard/types/widget';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWidgetError,
  selectWidgetData,
  setWidgetError,
} from '../../features/dashboard/dashboardSlice';
import { RootState } from '../../store';
import WidgetBody from './components/WidgetBody';
import cn from 'classnames';
import styles from './Widget.module.less';
import { WidgetConfig } from './types/common';

interface Props {
  widget: IWidget;
  onChangeParameter: (widgetId: string, value: any) => void;
}

const Widget: React.FC<Props> = ({ widget, onChangeParameter }) => {
  const [config, setConfig] = useState<WidgetConfig>({});

  const dispatch = useDispatch();
  const data = useSelector((state: RootState) =>
    selectWidgetData(state, widget.id),
  );
  // TODO this selector trigger unnecessary renders, need refactor

  const widgetId = widget.id || '';

  const handleChangeParameter = (value: any) => {
    if (widget.id) {
      onChangeParameter(widget.id, value);
    }
  };

  const parseConfig = () => {
    try {
      setConfig(JSON.parse(widget.component));
      dispatch(deleteWidgetError(widgetId));
    } catch (_) {
      dispatch(setWidgetError({ id: widgetId, message: 'Cannot parse JSON' }));
    }
  };

  useEffect(() => {
    parseConfig();
  }, [widget.component]);

  return (
    <div
      className={cn(styles.bodyWrap, {
        [styles.bordered]: !config.flat,
      })}
    >
      <WidgetBody
        widgetType={widget.type}
        data={data}
        config={config}
        onChange={handleChangeParameter}
        parameter={widget.parameter}
      />
    </div>
  );
};

export default Widget;
