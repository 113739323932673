import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, AutoComplete } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import styles from './AppSearch.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDashboardList,
  isDashboardLoading,
  selectDashboardList,
} from '../../features/dashboard/dashboardSlice';
import { DashboardListItem } from '../../features/dashboard/types/dashboard';
import { search } from './helper';

const AppSearch = () => {
  const intl = useIntl();
  const dashboards = useSelector(selectDashboardList);
  const isLoading = useSelector(isDashboardLoading);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [searchStr, setSearchStr] = useState<string>('');
  const [key, setKey] = useState('key');

  const searchPlaceholder = intl.formatMessage({
    id: 'app.SystemSearch',
    defaultMessage: 'Поиск по системе',
  });

  const dashboardsSectionTitle = intl.formatMessage({
    id: 'app.Dashboards',
    defaultMessage: 'Дашборды',
  });

  const renderItem = (title: string, id: string) => {
    return {
      value: id,
      label: (
        <div className={styles.itemTitle}>
          {title}
          <BarChartOutlined />
        </div>
      ),
    };
  };

  const filteredDashboards: DashboardListItem[] = search(dashboards, searchStr);

  const options = _.isEmpty(filteredDashboards)
    ? []
    : [
        {
          label: dashboardsSectionTitle,
          options: filteredDashboards.map(dashboard =>
            renderItem(dashboard.name, dashboard.id),
          ),
        },
      ];

  const handleFocus = () => {
    if (_.isEmpty(dashboards)) {
      dispatch(fetchDashboardList());
    }
  };

  const changeKey = (isOpen: boolean) => {
    // Изменение key автокомплита для анмаунта чтобы скролл вернулся в исходное положение
    //setTimeout чтобы анимация успела воспроизвестись
    if (!isOpen) {
      setTimeout(() => {
        setKey('');
        setKey('key');
      }, 500);
    }
  };

  const changeBodyStyles = (isOpen: boolean) => {
    // Изменение стилей body для предотвращения скролла всего документа при открытом автокомплите
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const handleDropdownVisibleChange = (isOpen: boolean) => {
    changeKey(isOpen);
    changeBodyStyles(isOpen);
  };

  const handleSelect = (dashboardId: any) => push(`/dashboard/${dashboardId}`);

  useEffect(() => {
    return () => changeBodyStyles(false);
  }, []);

  return (
    <AutoComplete
      key={key}
      dropdownMatchSelectWidth={500}
      className={styles.autoComplete}
      options={options}
      onChange={setSearchStr}
      onFocus={handleFocus}
      onSelect={handleSelect}
      value={searchStr}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    >
      <Input.Search
        placeholder={searchPlaceholder}
        className={styles.input}
        loading={isLoading}
      />
    </AutoComplete>
  );
};

export default AppSearch;
