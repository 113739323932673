import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Spin, Transfer } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styles from '../../SettingsUsers/styles.module.less';
import {
  addGroupLabel,
  editGroupLabel,
  groupNameLabel,
  saveLabel,
} from '../../SettingsUsers/AddUser/helper';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../../../error/errorSlice';
import {
  createGroup,
  fetchGroup,
  fetchGroupList,
  fetchUserList,
  receiveGroup,
  selectGroup,
  updateGroup,
} from '../../../settingsSlice';
import { GroupListItem } from '../../../types/group';
import { getFullName } from '../../../../../utils';
import { DashboardListItem } from '../../../../dashboard/types/dashboard';
import { Permissions, User } from '../../../types/user';
import { selectCurrentUserGrants } from '../../../../user/userSlice';
import { hasAccess } from '../../../../../utils/rights';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 16 },
};

interface AddGroupDrawerProps {
  setShowDrawer: (drawerState: boolean) => void;
  dashBoardsListForSelector: DashboardListItem[] | undefined;
  userListForSelector: User[] | undefined;
  showDrawer: boolean;
  isGroupEditing: boolean;
  openedGroup: GroupListItem | null;
}

const AddGroupDrawer: React.FC<AddGroupDrawerProps> = ({
  setShowDrawer,
  showDrawer,
  isGroupEditing = false,
  openedGroup,
  dashBoardsListForSelector,
  userListForSelector,
}) => {
  const userGrants = useSelector(selectCurrentUserGrants);
  const currentGroup = useSelector(selectGroup);
  const hasAccessToEdit = hasAccess([Permissions.GROUP_EDIT], userGrants);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  useEffect(() => {
    if (openedGroup?.id && showDrawer) {
      setLoading(true);
      dispatch(
        fetchGroup(openedGroup.id, () => {
          setLoading(false);
          setIsNameEmpty(!form.getFieldValue('name') || !isGroupEditing);
        }),
      );
    }
  }, [openedGroup?.id, showDrawer]);

  const intl = useIntl();

  useEffect(() => {
    form.resetFields();
  }, [form, showDrawer]);

  const onFinishFailed = (errorInfo: any) => dispatch(setError(errorInfo));

  const onClose = () => {
    setShowDrawer(false);
    dispatch(receiveGroup({}));
    setIsNameEmpty(true);
    form.resetFields();
  };

  const handleClick = () => form.submit();

  const onFinish = (values: any) => {
    if (isGroupEditing) {
      const updatedGroup = { id: openedGroup?.id, ...values }; // merge id's fields to form fields. form send values without id's (only described fields)
      dispatch(
        updateGroup(updatedGroup, () => {
          onClose();
          dispatch(fetchUserList({ size: 0, page: 0 }));
          dispatch(
            fetchGroupList({
              size: 0,
              page: 0,
              sort: {
                field: 'name',
                type: 'ASC',
              },
            }),
          );
        }),
      );
    } else {
      dispatch(
        createGroup(values, () => {
          onClose();
          dispatch(fetchUserList({ size: 0, page: 0 }));
          dispatch(
            fetchGroupList({
              size: 0,
              page: 0,
              sort: {
                field: 'name',
                type: 'ASC',
              },
            }),
          );
        }),
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value.replace(/^\s+/g, '');
    setIsNameEmpty(!nameValue);
    form.setFieldValue('name', nameValue);
  };

  const trimValue = () =>
    form.setFieldValue('name', form.getFieldValue('name')?.trim());

  const requiredFieldMessage = intl.formatMessage({
    id: 'Common.requiredField',
    defaultMessage: 'Обязательное поле',
  });
  const changeDashboards = intl.formatMessage({
    id: 'Common.changeDashboards',
    defaultMessage: 'Изменение состава дашбордов',
  });
  const changeUsers = intl.formatMessage({
    id: 'Common.changeUsers',
    defaultMessage: 'Изменение состава пользователей',
  });

  const handleTransferChange = (key: string) => (nextTargetKeys: string[]) =>
    form.setFieldValue(key, nextTargetKeys);

  const handleUserSearch = (value: string, option: User) => {
    const userInfo = getFullName(option.profile) || option.profile.email;
    return userInfo.toLowerCase().includes(value.toLowerCase());
  };

  const handleDashSearch = (value: string, option: DashboardListItem) =>
    option.name.toLowerCase().includes(value.toLowerCase());

  return (
    <>
      <Drawer
        title={
          <span className={styles.drawerTitle}>
            {isGroupEditing ? editGroupLabel : addGroupLabel}
          </span>
        }
        placement="left"
        width={470}
        closeIcon={<ArrowLeftOutlined />}
        onClose={onClose}
        open={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={handleClick}
              htmlType="submit"
              type="primary"
              disabled={isNameEmpty || !hasAccessToEdit || loading}
            >
              {saveLabel}
            </Button>
          </div>
        }
      >
        {!loading ? (
          <Form
            {...layout}
            name="basic"
            form={form}
            initialValues={{ ...currentGroup }}
            onFinish={onFinish}
            className={styles.formItem}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={groupNameLabel}
              name="name"
              rules={[{ required: true, message: requiredFieldMessage }]}
            >
              <Input
                disabled={!hasAccessToEdit}
                onBlur={trimValue}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              name="dashboardIds"
              label={changeDashboards}
              rules={[
                {
                  required: true,
                  message: requiredFieldMessage,
                  type: 'array',
                },
              ]}
              valuePropName="targetKeys"
              className="selectContainer"
            >
              <Transfer
                showSearch
                disabled={!hasAccessToEdit}
                filterOption={handleDashSearch}
                onChange={handleTransferChange('dashboardIds')}
                dataSource={dashBoardsListForSelector}
                rowKey={item => item.id}
                render={dash => dash.name}
              />
            </Form.Item>
            <Form.Item
              label={changeUsers}
              name="userIds"
              rules={[
                {
                  required: true,
                  message: requiredFieldMessage,
                  type: 'array',
                },
              ]}
              valuePropName="targetKeys"
              className="selectContainer"
            >
              <Transfer
                showSearch
                disabled={!hasAccessToEdit}
                onChange={handleTransferChange('userIds')}
                dataSource={userListForSelector}
                filterOption={handleUserSearch}
                rowKey={item => item.profile.id}
                render={user => getFullName(user.profile) || user.profile.email}
              />
            </Form.Item>
          </Form>
        ) : (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default AddGroupDrawer;
