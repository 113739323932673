import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import layoutStyles from '../layout.module.scss';
import ManagerNavbar from '../ManagerNavbar';

interface Props {
  title?: string;
  children: ReactNode;
  toolbar?: ReactNode;
  onChangeTitle: (value: string) => void;
}

const { Header, Content } = Layout;

const DashboardLayout: React.FC<Props> = ({
  children,
  title,
  toolbar,
  onChangeTitle,
}) => {
  return (
    <Layout>
      <Header className={layoutStyles.header}>
        <ManagerNavbar title={title} onChangeTitle={onChangeTitle}>
          {toolbar}
        </ManagerNavbar>
      </Header>
      <Content className={layoutStyles.content}>{children}</Content>
    </Layout>
  );
};

export default DashboardLayout;
