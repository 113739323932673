import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildInfoSelector, fetchBuildInfo } from '../layoutSlice';
import styles from '../layout.module.scss';

const BuildVersion = () => {
  const buildInfo = useSelector(buildInfoSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuildInfo());
  }, []);

  return (
    <div className={styles.buildInfo}>
      {buildInfo
        ? `v${buildInfo.build.version}#${buildInfo.commit.id.abbrev}`
        : ''}
    </div>
  );
};

export default BuildVersion;
